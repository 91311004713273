<template>
  <v-container>
    <v-card elevation="0" class="indigo" style="background: linear-gradient(45deg, #03A9F4, #0000007d);">
      <v-card-title class="">
        <span>{{ title }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
    </v-card>
    <!-- 时间线 -->
    <!-- 项目管理员管理界面 -->
    <v-row>
      <v-col cols="12" sm="5" offset="7" align-self="center">
        <v-text-field v-model="searchUser" solo dense class="" append-icon="mdi-magnify" label="搜索" single-line
          hide-details></v-text-field>
      </v-col>
    </v-row>
    <v-data-table :headers="projectTableHeaders" :items="projectAdminList" :search="searchUser" item-key="projectAdmin"
      class="order-table-hover">
      <template v-slot:[`item.applyStatus`]="{ item }">
        <v-chip :color="getStatusColor(item.applyStatus)" dark>
          {{ getStatus(item.applyStatus) }}
        </v-chip>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ getType(item.type) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click="editItem(item, '修改项目管理员')" v-on="on" v-bind="attrs">
              mdi-pencil
            </v-icon>
          </template>
          <span>修改</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click="reloadPws(item)" v-on="on" v-bind="attrs">
              mdi-reload
            </v-icon>
          </template>
          <span>重置密码</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="creatDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.username" label="用户名" :rules="formRules.usernameRules"
                    :disabled="!formType">
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="formType">
                  <v-text-field v-model="form.password" label="密码" type="password" required
                    :rules="formRules.passwordRules">
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="formType">
                  <v-text-field v-model="form.confirmPassword" label="确认密码" type="password" required
                    :rules="rules.confirmPasswordRules"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="邮箱" v-model="form.email" required :rules="formRules.emailRules"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="手机号" v-model="form.phoneNumber" :rules="formRules.phoneNumberRules" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="form.name != null">
                  <v-text-field label="姓名" v-model="form.name" disabled></v-text-field>
                </v-col>
                <v-col cols="12" v-if="form.idCard != null">
                  <v-text-field label="身份证" v-model="form.idCard" disabled></v-text-field>
                </v-col>
                <v-col cols="12" v-if="form.companyName != null">
                  <v-text-field label="公司名称" v-model="form.companyName" disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="form.businessLicense != null">
                  <v-text-field label="工商执照" v-model="form.businessLicense" disabled>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="4" v-if="form.frontPhoto != null">
                  <v-img :src="form.frontPhoto" @click="showImg(form.frontPhoto)"></v-img>
                  <p class="text-center caption">身份证正面照</p>
                </v-col>
                <v-col cols="12" sm="4" offset="1" v-if="form.backPhoto != null">
                  <v-img :src="form.backPhoto" @click="showImg(form.backPhoto)"></v-img>
                  <p class="text-center caption">身份证反面照</p>
                </v-col>
                <v-col cols="12" sm="4" v-if="form.businesslicensePhoto != null">
                  <v-img :src="form.businesslicensePhoto" @click="showImg(form.businesslicensePhoto)"></v-img>
                  <p class="text-center caption">营业执照</p>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="邀请码" disabled v-if="form.invitationCode != null" v-model="form.invitationCode"
                    required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="creatDialog = false">
            取消
          </v-btn>

          <v-btn color="blue darken-1" text @click="editUser">
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
// let vm;
import axios from "axios"; //基于Promise的HTTP客户端
import rules from "../../config/rules";
// //import qs from "qs"
import commonCfg from "../../config/common";
let commonUrl = commonCfg.commonUrl;
let version = commonCfg.urlVersion;
// let commonUrl = "http://192.168.1.104:64011/";
// let version = "";
export default {
  data () {
    return {
      title: "项目管理员管理",
      projectAdminList: [],
      proUserList: [],
      sysAdminList: [],
      url: "ProjectAdmin",
      formType: true, // true-创建 false-修改
      creatDialog: false,
      tabs: [
        {
          text: "项目管理员"
        },
        {
          text: "专业人才"
        },
        {
          text: "管理员"
        }
      ],
      tab: 0,
      // 添加用户表单
      form: {
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        phoneNumber: "",
        idCard: "",
        name: "",
        companyName: "",
        businessLicense: "",
        frontPhoto: "",
        backPhoto: "",
        businesslicensePhoto: "",
        invitationCode: ""
      },
      projectTableHeaders: [
        {
          text: "用户名",
          align: "start",
          sortable: false,
          value: "username"
        },
        { text: "电话", sortable: false, value: "phoneNumber" },
        { text: "邮箱", sortable: false, value: "email" },
        { text: "账号类型", sortable: false, value: "type" },
        { text: "认证状态", sortable: false, value: "applyStatus" },
        { text: "操作", sortable: false, value: "actions" }
      ],
      proTableHeaders: [
        {
          text: "用户名",
          align: "start",
          sortable: false,
          value: "username"
        },
        { text: "电话", sortable: false, value: "phoneNumber" },
        { text: "邮箱", sortable: false, value: "email" },
        { text: "操作", sortable: false, value: "actions" }
      ],
      adminTableHeaders: [
        {
          text: "用户名",
          align: "start",
          sortable: false,
          value: "username"
        },
        { text: "电话", sortable: false, value: "phoneNumber" },
        { text: "邮箱", sortable: false, value: "email" },
        { text: "操作", sortable: false, value: "actions" }
      ],
      formRules: rules.rules,
      rules: {
        confirmPasswordRules: [
          v => !!v || "确认密码为必填",
          v => v === this.form.password || "密码不匹配"
        ]
      },
      formTitle: "",
      searchUser: ""
    };
  },
  async created () {
    this.getProjectAdminList();
  },
  watch: {
    form: {
      deep: true,
      handler (val) {
        val.username = val.username.toLowerCase();
      }
    }
  },
  props: ["role", "username", "invitationCode"],
  methods: {
    // 获取项目管理员
    getProjectAdminList () {
      axios.get(`${commonUrl}listExternalProjectAdmin${version}`).then(res => {
        this.projectAdminList = res.data.result;
      });
    },

    // 根据认证状态显示颜色
    getStatusColor (status) {
      let color = "blue-grey";
      switch (status) {
        case "pass":
          color = "green";
          break;
        case "checking":
          color = "orange";
          break;
        case "refuse":
          color = "red";
          break;
        default:
          color = "blue-grey";
      }
      return color;
    },
    // 显示认证状态
    getStatus (status) {
      let str = "未认证";
      switch (status) {
        case "pass":
          str = "已认证";
          break;
        case "checking":
          str = "认证中";
          break;
        case "refuse":
          str = "认证失败";
          break;
        default:
          str = "未认证";
      }
      return str;
    },
    getType (type) {
      if (type == 0) {
        return "个人用户";
      } else if (type == 1) {
        return "企业用户";
      } else if (type == 3) {
        return "内部用户";
      } else {
        return "";
      }
    },
    createUser (title) {
      this.form = {};
      this.creatDialog = true;
      this.formTitle = title;
      this.formType = true;
    },
    // 修改用户资料
    editItem (item, title) {
      axios
        .get(
          `${commonUrl}listOne${this.url}${version}?username=` + item.username
        )
        .then(res => {
          this.form = {
            username: res.data.result.username,
            email: res.data.result.email,
            phoneNumber: res.data.result.phoneNumber,
            name: res.data.result.name,
            idCard: res.data.result.idCard,
            companyName: res.data.result.companyName,
            businessLicense: res.data.result.businessLicense,
            invitationCode: res.data.result.invitationCode,
            frontPhoto:
              res.data.result.frontPhotoPath == null
                ? null
                : "data:image/jpeg;base64," + res.data.result.frontPhotoPath,
            backPhoto:
              res.data.result.backPhotoPath == null
                ? null
                : "data:image/jpeg;base64," + res.data.result.backPhotoPath,
            businesslicensePhoto:
              res.data.result.businessLicensePath == null
                ? null
                : "data:image/jpeg;base64," +
                res.data.result.businessLicensePath
          };
          this.formTitle = title;
          this.creatDialog = true;
          this.formType = false;
        });
    },
    // 重置用户密码
    reloadPws (item) {
      this.$dialog
        .confirm({
          text: "是否重置此用户密码",
          title: "重置密码",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确认",
              color: "blue",
              key: true
            }
          ]
        })
        .then(type => {
          if (type) {
            let formData = new FormData();
            formData.append("username", item.username);
            axios
              .post(`${commonUrl}reloadPws${version}`, formData)
              .then(res => {
                if (res.data.code == "200") {
                  this.$emit("tips", "重置密码成功！", "green");
                  this.getUserList();
                } else if (res.data.message != null) {
                  this.$emit("tips", res.data.message, "red");
                } else {
                  this.$emit("tips", "重置密码失败！", "red");
                }
              });
          }
        });
    },
    // 添加新用户
    addUser () {
      if (this.$refs.form.validate()) {
        let param = {
          username: this.form.username,
          password: this.form.password,
          email: this.form.email,
          phoneNumber: this.form.phoneNumber
        };
        axios.post(`${commonUrl}add${this.url}${version}`, param).then(res => {
          if (res) {
            if (res.data.code == "200") {
              console.log(res);
              this.$emit("tips", "创建成功！", "green");
              this.creatDialog = false;
              this.reload();
            } else if (res.data.message != null) {
              this.$emit("tips", res.data.message, "red");
            } else {
              this.$emit("tips", "创建失败！", "red");
            }
          }
        });
      }
    },
    // 修改用户资料
    editUser () {
      if (this.$refs.form.validate()) {
        let form = {
          username: this.form.username,
          email: this.form.email,
          phoneNumber: this.form.phoneNumber
        };
        axios.post(`${commonUrl}edit${this.url}${version}`, form).then(res => {
          if (res.data.code == "200") {
            console.log(res);
            this.$emit("tips", "修改成功！", "green");
            this.creatDialog = false;
            this.reload();
          } else if (res.data.message != null) {
            this.$emit("tips", res.data.message, "red");
          } else {
            this.$emit("tips", "修改失败！", "red");
          }
        });
      }
    },
    reload () {
      if (this.tab == 0) {
        this.getProjectAdminList();
      } else if (this.tab == 1) {
        this.getProUserList();
      } else if (this.tab == 2) {
        this.getAdminList();
      }
    }
  }
};
</script>
